<template>
    <div>
        <div class="borrowing" v-if="details.jobseeker_info">
            <div class="title_text col_1A1A1A" v-if="type == 1"><span class="iconfont icon-tishi"></span>
                <span class="txt">工作人员会尽快处理，请耐心等待审核结果</span>
            </div>
            <div class="title_text col_1A1A1A" v-if="type == 2"><span class="iconfont icon-tishi"></span>
                <span class="txt">驻厂审批已通过，请等待放款！</span>
            </div>
            <div class="title_text col_1A1A1A" v-if="type == 3"><span class="iconfont icon-tishi"></span>
                <span class="txt">借支金额已发放，请注意查收！</span>
            </div>
            <div class="title_text col_1A1A1A" v-if="type == 4"><span class="iconfont icon-tishi"></span>
                <span class="txt">借支申请已被驳回！</span>
            </div>
            <!-- 在职信息 -->
            <div class="block">
                <h3>在职信息</h3>
                <div class="content">
                    <div class="cell">
                        <div class="left">
                            <div class="title">在职工厂</div>
                        </div>
                        <div class="right">
                            <div class="text">{{details.jobseeker_info.factory_name}}</div>
                        </div>
                    </div>
                    <div class="cell" v-if="details.jobseeker_info.job_name">
                        <div class="left">
                            <div class="title">职位名称</div>
                        </div>
                        <div class="right">
                            <div class="text">{{details.jobseeker_info.job_name}}</div>
                        </div>
                    </div>
                    <div class="cell">
                        <div class="left">
                            <div class="title">入职日期</div>
                        </div>
                        <div class="right">
                            <div class="text">{{details.jobseeker_info.employ_time}}</div>
                        </div>
                    </div>
                    <div class="cell" v-if="details.jobseeker_info.incumbent_day > -1">
                        <div class="left">
                            <div class="title">在职天数</div>
                        </div>
                        <div class="right">
                            <div class="text">{{details.jobseeker_info.incumbent_day}}天</div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 借支申请 -->
            <div class="block" v-if="details.advanced_payment_info.info_time">
                <h3>
                    <div>借支申请</div>
                    <div class="look" v-if="details.advanced_payment_info.attachment.length" @click="lookImg(details.advanced_payment_info.attachment)">查看附件</div>

                </h3>
                <div class="content">
                    <div class="cell">
                        <div class="left">
                            <div class="title">申请时间</div>
                        </div>
                        <div class="right">
                            <div class="text">{{details.advanced_payment_info.info_time}}</div>
                        </div>
                    </div>
                    <div class="cell">
                        <div class="left">
                            <div class="title">借支金额</div>
                        </div>
                        <div class="right">
                            <div class="text">{{details.advanced_payment_info.money}}元</div>
                        </div>
                    </div>
                    <div class="cell note" v-if="details.advanced_payment_info.note">
                        <div class="left">
                            <div class="title">借支原因</div>
                        </div>
                        <div class="right">
                            <pre class="text">{{details.advanced_payment_info.note}}</pre>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 驻厂审批信息 -->
            <div class="block" v-if="details.resident_audit_info.info_time">
                <h3>驻厂审批信息</h3>
                <div class="content">
                    <div class="cell">
                        <div class="left">
                            <div class="title">审批时间</div>
                        </div>
                        <div class="right">
                            <div class="text">{{details.resident_audit_info.info_time}}</div>
                        </div>
                    </div>
                    <div class="cell">
                        <div class="left">
                            <div class="title">审批人</div>
                        </div>
                        <div class="right">
                            <div class="text">{{details.resident_audit_info.name}}</div>
                        </div>
                    </div>
                    <div class="cell">
                        <div class="left">
                            <div class="title">审批结果</div>
                        </div>
                        <div class="right">
                            <div class="text">{{details.resident_audit_info.status}}</div>
                        </div>
                    </div>
                    <div class="cell">
                        <div class="left">
                            <div class="title">审批金额</div>
                        </div>
                        <div class="right">
                            <div class="text">{{details.resident_audit_info.money}}元</div>
                        </div>
                    </div>
                    <div class="cell note" v-if="details.resident_audit_info.note">
                        <div class="left">
                            <div class="title">审批备注</div>
                        </div>
                        <div class="right">
                            <pre class="text">{{details.resident_audit_info.note}}</pre>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 配置账号审批信息 -->
            <div class="block" v-if="details.audit_process_info.info_time">
                <h3>配置账号审批信息</h3>
                <div class="content">
                    <div class="cell">
                        <div class="left">
                            <div class="title">审批时间</div>
                        </div>
                        <div class="right">
                            <div class="text">{{details.audit_process_info.info_time}}</div>
                        </div>
                    </div>
                    <div class="cell">
                        <div class="left">
                            <div class="title">审批人</div>
                        </div>
                        <div class="right">
                            <div class="text">{{details.audit_process_info.name}}</div>
                        </div>
                    </div>
                    <div class="cell">
                        <div class="left">
                            <div class="title">审批结果</div>
                        </div>
                        <div class="right">
                            <div class="text">{{details.audit_process_info.status}}</div>
                        </div>
                    </div>
                    <div class="cell">
                        <div class="left">
                            <div class="title">审批金额</div>
                        </div>
                        <div class="right">
                            <div class="text">{{details.audit_process_info.money}}元</div>
                        </div>
                    </div>
                    <div class="cell note" v-if="details.audit_process_info.note">
                        <div class="left">
                            <div class="title">审批备注</div>
                        </div>
                        <div class="right">
                            <pre class="text">{{details.audit_process_info.note}}</pre>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 财务放款信息 -->
            <div class="block" v-if="details.finance_audit_info.info_time">
                <h3>
                    <div>财务放款信息</div>
                    <div class="look" v-if="details.finance_audit_info.attachment.length" @click="lookImg(details.finance_audit_info.attachment)">查看附件</div>
                </h3>
                <div class="content">
                    <div class="cell">
                        <div class="left">
                            <div class="title">放款时间</div>
                        </div>
                        <div class="right">
                            <div class="text">{{details.finance_audit_info.info_time}}</div>
                        </div>
                    </div>
                    <div class="cell">
                        <div class="left">
                            <div class="title">操作人</div>
                        </div>
                        <div class="right">
                            <div class="text">{{details.finance_audit_info.name}}</div>
                        </div>
                    </div>
                    <div class="cell">
                        <div class="left">
                            <div class="title">放款结果</div>
                        </div>
                        <div class="right">
                            <div class="text">{{details.finance_audit_info.status}}</div>
                        </div>
                    </div>
                    <div class="cell">
                        <div class="left">
                            <div class="title">放款金额</div>
                        </div>
                        <div class="right">
                            <div class="text">{{details.finance_audit_info.money}}元</div>

                        </div>
                    </div>
                    <div class="cell note" v-if="details.finance_audit_info.note">
                        <div class="left">
                            <div class="title">放款备注</div>
                        </div>
                        <div class="right">
                            <pre class="text">{{details.finance_audit_info.note}}</pre>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 冲销记录 -->
            <div class="block" v-if="details.payment_reverse_info && details.payment_reverse_info.length">
                <h3>
                    <div>冲销记录详情</div>
                    <div class="look" @click="go_to_writedetail">更多记录</div>
                </h3>
                <div class="content">
                    <div class="cell">
                        <div class="left">
                            <div class="title">已冲销金额</div>
                        </div>
                        <div class="right">
                            <div class="text">{{details.payment_reverse_info[0].money}}元</div>
                        </div>
                    </div>
                    <div class="cell">
                        <div class="left">
                            <div class="title">还款时间</div>
                        </div>
                        <div class="right">
                            <div class="text">{{details.payment_reverse_info[0].repayment_time}}</div>
                        </div>
                    </div>
                    <div class="cell">
                        <div class="left">
                            <div class="title">冲销时间</div>
                        </div>
                        <div class="right">
                            <div class="text">{{details.payment_reverse_info[0].info_time}}</div>
                        </div>
                    </div>
                    <div class="cell">
                        <div class="left">
                            <div class="title">冲销状态</div>
                        </div>
                        <div class="right">
                            <div class="text">{{details.payment_reverse_info[0].status}}</div>
                        </div>
                    </div>
                    <div class="cell">
                        <div class="left">
                            <div class="title">冲销类型</div>
                        </div>
                        <div class="right">
                            <div class="text">{{details.payment_reverse_info[0].reverse_type}}</div>
                        </div>
                    </div>
                    <div class="cell">
                        <div class="left">
                            <div class="title">待冲销金额</div>
                        </div>
                        <div class="right">
                            <div class="text">{{details.payment_reverse_info[0].advanced_payment_money}}元</div>
                        </div>
                    </div>
                    <div class="cell note" v-if="details.payment_reverse_info[0].note">
                        <div class="left">
                            <div class="title">备注</div>
                        </div>
                        <div class="right">
                            <pre class="text">{{details.payment_reverse_info[0].note}}</pre>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="bottom_back">
            <div class="bottom_back_on" @click="gobanck">
                <div class="bottom_back_wenz">返回</div>
                <div class="iconfont icon-fanhui"></div>
            </div>
        </div>
        <!-- 弹窗 -->
    </div>
</template>
<script>
import '@/assets/css/index.css'
// import { getBorrowingDetails } from "@/api/home";
import { ImagePreview } from 'vant';
export default {
    data() {
        return {
            details: {},
            borrowing_id: 0,
            type: 1,
        };
    },
    mounted() {
        this.$store.commit("getWxSDK");
        this.$store.commit("hideTabbar");
        this.borrowing_id = this.$route.query.borrowing_id
        this.type = this.$route.query.type
        this.getDetails()
    },
    methods: {
        gobanck() {
            this.$router.go(-1);
        },
        //
        go_to_writedetail() {
            this.$router.push({ path: '/writeDetails', query: { borrowing_id: this.borrowing_id } });
        },
        onClickLeft() {
            this.$router.go(-1)
        },
        // 查看全部图片
        lookImg(array) {
            ImagePreview(array);
        },
        getDetails() {
            var data = {
                team_id: localStorage.getItem('team_id'),
                advanced_payment_id: this.borrowing_id
            }
            this.Request('client/advanced_payment/info', 'get', data).then(res => {
                if (res.status == 0) {
                    this.details = res.data
                }
            })
        },
    }
}
</script>
<style scoped>
.borrowing {
    /* margin: 0 30px 30px; */
    /* padding-top: 120px; */
    text-align: left;
}
.block {
    background: #fff;
    padding: 30px 30px 10px;
    border-radius: 10px;
    margin-top: 20px;
    text-align: left;
}
/* .block:first-of-type{margin-top: 16px;} */
.block h3 {
    display: flex;
    justify-content: space-between;
}
.block .look {
    color: #0abbb9;
    font-weight: 400;
}
/* input输入框样式 */
.cell {
    display: flex;
    border-bottom: 2px solid #f8f8f8;
    padding: 30px 0;
    position: relative;
    /* line-height: 40px; */
    align-items: center;
}
.cell .left {
    display: flex;
    width: 28%;
}
.cell .left .must {
    color: red;
    margin: 6px 10px 0 0;
    width: 10px;
}
.cell .left .title {
    min-width: 132px;
}
.cell .right {
    width: 75%;
    display: flex;
    justify-content: space-between;
}
.cell .right .text {
    width: 100%;
    overflow: hidden;
    text-align: right;
    text-overflow: ellipsis;
    border: 0;
    background: transparent;
}
.note {
    display: block;
}
.note .right {
    width: 100%;
    text-align: left;
    margin-top: 16px;
}
.note .right .text {
    text-align: left;
    white-space: pre-wrap;
    word-wrap: break-word;
}
.block .cell:last-of-type {
    border: 0;
}
.icon-tishi {
    font-size: 34px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    line-height: 60px;
    margin-top: 2px;
}
.title_text {
    padding: 0 30px;
    color: #0abbb9;
    background-color: #ecfbfb;
    height: 60px;
    display: flex;
    align-items: center;
    font-size: 26px;
}
.txt{
    display: inline-block;
    margin-top:3px ;
}
</style>